// i18next-extract-mark-ns-start products-valdyn-cs

import { VALDYN_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductValdynPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const image = getImage(data.image.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<h2>Simulating reality using VALDYN</h2>
						<p>
							As part of Realis Simulation’s team+ initiative,
							employees were challenged to create a graphic or
							animation that highlights and demonstrates Realis
							Simulation’s unique features in an innovative and fun
							way.
						</p>
						<p>
							Jonathan Plail, a Principal Software Engineer at Realis
							Simulation, submitted the idea of modelling a mannequin
							on a swinging swing.&nbsp; Using{' '}
							<a href="/Products/VALDYN">VALDYN</a>, the model was
							created with the purpose of achieving a large swing
							angle in as little time as possible. The full power of
							VALDYN’s dynamic solver was put to use, demonstrating
							two valuable features of the software: solving generic
							equations of motion and powerful 2D animations.
						</p>
						<p>
							The initial steps included connecting beams and 2D
							shapes to make the structure of the swing, followed by
							adding the downward force of gravity.
						</p>
						<p>
							It was assumed that the main driver for actuating the
							swing was the movement of the center of mass, like in
							the real world where a rider shifts their legs to gain
							momentum. When the mass moved, the model would attempt
							to restore equilibrium;&nbsp;and a control strategy was
							implemented to move the center of mass through each
							phase. When the mannequin’s head had positive horizontal
							velocity, the legs would straighten to move the center
							of gravity and feet forwards. When the velocity was
							negative, the legs and would bend to move the feet back.
						</p>
						<p>
							With some experimentation and fine tuning of the maximum
							bend angle and rate of change, Jon was able to
							successfully demonstrate VALDYN’s power and potential in
							an innovatively realistic swinging model.
						</p>
					</Trans>
					<p>
						{image && (
							<GatsbyImage image={image} alt="VALDYN swing" />
						)}
					</p>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{VALDYN_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductValdynPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-valdyn-cs", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/valdyn-hero.jpg" }) {
		...imageBreaker
	}
	image: file(relativePath: { eq: "content/valdyn-swing.png" }) {
		...imageStandard
	}
}
`;

